<template>
    <div v-if="page_loading">
        <loading :page_loading="page_loading" />
    </div>
    <div v-else>
        <loading :loading="loading" />

        <v-card elevation="1" class="v-card-bottom-30">
          <v-card-title>
            <h4>ตั้งค่า</h4> <v-icon>mdi-chevron-right</v-icon><span class="font-weight-regular body-1">หมวดหมู่</span>
            <v-spacer/>
          </v-card-title>
        </v-card>

        <!-- จัดการหมวดหมู่สินค้า -->
        <v-card class="mb-4">
            <v-card-title>
                <!-- <v-icon color="#FF0D00" size="32px">mdi-cart-outline </v-icon> -->
                <span class="ml-4">หมวดหมู่หลัก</span>
                <v-spacer/>
                <v-btn @click="mcategorytype(null, 'ADD') " dark color="primary"><v-icon left>mdi-plus</v-icon> เพิ่ม</v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text class="pb-0">
                <v-row row>
                    <v-col class="pb-0" cols="12" md="12">
                        <div style="max-height: 500px; min-height: 150px;" class="overflow-y-auto">
                            <v-data-table :headers="headers1" :items="managecategory" class="elevation-0 packhai-border-table" hide-default-footer :items-per-page="10000">
                            <template v-slot:item.num="{ item }">
                                {{  (managecategory.indexOf(item)+1) }}
                            </template>
                            <template v-slot:item.Name="{ item }">
                                <v-row>
                                    {{ (item.Name==null? ' ' :item.Name) }}
                                </v-row>
                                <v-row>
                                    {{ (item.ParentName==null? ' ' :item.ParentName) }}
                                </v-row>
                            </template>
                            <template v-slot:item.Description="{ item }">
                                {{ (item.Description==null? '' :item.Description) }}
                            </template>
                            <template v-slot:item.edit="{ item }">
                                <v-btn v-if="item.IsHidden == 1"  icon disabled><v-icon size="18">fa-edit</v-icon></v-btn>
                                <v-btn v-else @click="mcategorytype(item, 'EDIT') "  icon ><v-icon size="18">fa-edit</v-icon></v-btn>
                            </template>
                            <template v-slot:item.delete="{ item }">
                                <v-btn v-if="item.IsHidden == 1"  icon disabled><v-icon size="18">fa-trash-alt</v-icon></v-btn>
                                <v-btn v-else @click="mcategorytype(item, 'DELETE')" icon ><v-icon size="18" color="danger">fa-trash-alt</v-icon></v-btn>
                            </template>
                            </v-data-table>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <v-dialog v-model="dialog_managecategory" :fullscreen="isFullscreen"  scrollable max-width="45%" persistent >
            <v-card style="background: #f0f0f0" class="my-0">
            <v-card-title >
                <span v-if="categorytypes == 'EDIT'">แก้ไขคลังสินค้า</span>
                <span v-else-if="categorytypes == 'ADD'">เพิ่มคลังสินค้า</span>
                <v-spacer></v-spacer>
                <v-btn icon  @click="dialog_managecategory = false, IsReadonly = false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text>
                <v-row row >
                    <v-col class="pb-0" cols="12" md="12">
                        <v-row row >
                            <v-col class="pb-0" cols="12" md="3">
                                <h4>  หมวดหมู่หลัก  :</h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="9">
                                <v-select
                                    v-model = "selectcategories"
                                    :items = "scategory"
                                    item-text="Name"
                                    item-value= "value"
                                    single-line
                                    dense
                                    persistent-hint
                                    return-object
                                    hide-details
                                    outlined >
                                        <template slot="item" slot-scope="items">
                                            <v-col cols="8" class="body-2">
                                                {{ items.item.Name }}
                                            </v-col>
                                            <v-col cols="4" class="caption">
                                                {{ items.item.ParentName }}
                                            </v-col>
                                        </template>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row row >
                    <v-col class="pb-0" cols="12" md="12">
                        <v-row row >
                            <v-col class="pb-0" cols="12" md="3">
                                <h4>ชื่อหมวดหมู่สินค้า :</h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="9">
                                <v-text-field
                                    ref="name"
                                    :value="name"
                                    @change="value => name = value"
                                    autocomplete="off"
                                    autofocus
                                    @keyup.enter="$refs.description.focus()"
                                    outlined
                                    dense
                                    hide-details
                                    required />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row row >
                    <v-col class="pb-0" cols="12" md="12">
                        <v-row row >
                            <v-col class="pb-0" cols="12" md="3">
                                <h4>รายละเอียด :</h4>
                            </v-col>
                            <v-col class="pb-0" cols="12" md="9">
                                <v-textarea
                                    ref="description"
                                    autocomplete = "off"
                                    v-model="description"
                                    @keyup.enter="categorytypeConfirm()"
                                    outlined
                                    dense
                                    hide-details
                                    required />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                <v-col class="pb-0" cols="12" md="12">
                    <v-row row >
                    <v-col class="pb-0" cols="12" md="12" align="left">
                        <v-btn dark color="primary" @click="categorytypeConfirm()" class="mr-1"><v-icon left >mdi-content-save</v-icon> บันทึก</v-btn>
                    </v-col>
                    </v-row>
                </v-col>
                </v-row>
            </v-card-text>
            </v-card>
      </v-dialog>

    </div>
</template>
<script>
import { manageCategoryService } from '@/website/global'
import Loading from '@/website/components/Loading'
import axios from 'axios'
export default {
    components: {
        Loading
    },
    data(){
        return{
            page_loading: true,
            loading: false,
            CategoryID:'',
            name: '',
            oddname: '',
            description: '',
            scategory: [],
            selectcategories: {"ID": 0, "Name": "--เลือกหมวดหมู่--"},
            categorytypes: null,
            dialog_managecategory: false ,
            isFullscreen : false,
            IsReadonly : false,
            managecategory: [],
            headers1: [
              { text: 'ลำดับ', align: 'center', sortable: false, value: 'num', width: '100px' },
              { text: 'ชื่อ', align: 'left', value: 'Name', sortable: false },
              { text: 'รายละเอียดหมวดหมู่', align: 'left', value: 'Description', sortable: false },
              { text: 'แก้ไข', align: 'center', value: 'edit', sortable: false , width: '100px'},
              { text: 'ลบ', align: 'center', value: 'delete', sortable: false , width: '100px'},
              // { text: '', align: 'center', value: 'setDefault', sortable: false , width: '100px' },
            ],
        }
    },
    async created() {
        await this.loaddatacategory()
        this.page_loading = false
    },
    methods:{
        async loaddatacategory(){
            this.loading = true
            let response = await axios.post(manageCategoryService+'manageCategory/get_data_category', {
                'ShopID': localStorage.getItem("shop_id"),
                'BranchID': localStorage.getItem("branch_id")
            }, {headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('token')}} )
            if (response.data.Status){
                if(response.data.Data.DataCategoryManage.length > 0) {
                    this.managecategory = response.data.Data.DataCategoryManage
                }
                if (response.data.Data.SelectCategory.length > 0) {
                    this.scategory = response.data.Data.SelectCategory
                }
              this.loading = false
            } else {
              this.$swal({
                type: 'warning',
                title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
              })
               this.loading = false
            }
        },
        async mcategorytype (item, type) {
            if (type == 'ADD') {
              this.clearData()
              this.dialog_managecategory = true
              this.categorytypes = type
              this.selectcategories = this.scategory[0]
            //   this.AddressManageID = item
            } else if (type == 'EDIT') {
                this.categorytypes = type
                this.CategoryID = item.ID
                this.name = item.Name
                this.oddname = item.Name
                this.description = item.Description
                for (var i in this.scategory) {
                    if (this.scategory[i]["ID"] == item.ParentID) {
                        this.selectcategories = this.scategory[i]
                    }
                }
                this.dialog_managecategory = true
            }else if (type == 'DELETE'){
                this.categorytypes = type
                this.$swal({
                    position: "center",
                    type: 'warning',
                    title: 'ลบ ใช่หรือไม่ !!',
                    confirmButtonText: 'ยืนยัน',
                    cancelButtonText: 'ยกเลิก',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    allowOutsideClick: false
                }).then(async(result) => {
                    if (result.value) {
                    this.categorytypes = type
                    this.CategoryID = item.ID
                    this.categorytypeConfirm()
                    }
                })
            }
        },
        async categorytypeConfirm(){
            if(this.categorytypes == 'ADD'){
                if(this.name == null || this.name == ''){
                    this.$refs.name.focus()
                    return
                }
            this.loading = true
            // if(this.categorytypes == 'ADD' && 0==1){
                var Datainsert = {
                'ShopID': localStorage.getItem("shop_id"),
                'BranchID':localStorage.getItem("branch_id"),
                'StaffID':localStorage.getItem("staff_id"),
                'TypeAction' : this.categorytypes,
                'CategoryID': this.CategoryID,
                'Name': this.name,
                'Description': this.description,
                'Selectcategories':this.selectcategories
            }
                let response = await axios.post(manageCategoryService+'manageCategory/insert_category', {
                    'Datainsert' : Datainsert
                })
                if (response.data.Status){
                    if (response.data.Data.check_name == true) {
                        await this.$swal({
                            position: 'center',
                            type: 'error',
                            text: response.data.Data.Message,
                        })
                    }
                    else {
                        await this.$swal({
                            position: 'center',
                            type: 'success',
                            text: response.data.Data.Message,
                        })
                    }
                    this.dialog_managecategory = false
                    this.loading = false
                }else{
                    await this.$swal({
                            position: 'center',
                            type: 'error',
                            text: "เกิดข้อผิดพลาด",
                        })
                }
                this.dialog_managecategory = false
                this.loading = false
                this.loaddatacategory()
            }else if(this.categorytypes == 'EDIT'){
                var Dataupdate = {
                    'ShopID': localStorage.getItem("shop_id"),
                    'BranchID':localStorage.getItem("branch_id"),
                    'StaffID':localStorage.getItem("staff_id"),
                    'TypeAction' : this.categorytypes,
                    'CategoryID': this.CategoryID,
                    'NewName': this.name,
                    'Description': this.description,
                    'Selectcategories':this.selectcategories
                }
                let response = await axios.post(manageCategoryService+'manageCategory/update_category', {
                    'Dataupdate' : Dataupdate
                })
                if (response.data.Status){
                this.$swal({
                    toast: true,
                    timer: 1500,
                    showConfirmButton: false,
                    position: 'center',
                    type: 'success',
                    title: 'ดำเนินการเรียบร้อย'
                })
                this.dialog_managecategory = false
                this.loaddatacategory()
                }else {
                    this.$swal({
                    type: 'warning',
                    title: 'มีข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้ง!!',
                    })
                    this.dialog_managecategory = false
                    this.loading = false
                }
            }else if(this.categorytypes == 'DELETE'){
                this.loading = true
                var Datadelete = {
                    'CategoryID': this.CategoryID,
                }
                let response = await axios.post(manageCategoryService+'manageCategory/delete_category', {
                    'Datadelete' : Datadelete
                })
                if(response.data.Status){
                    if(response.data.Data.checkparent == true){
                        await this.$swal({
                            position: 'center',
                            type: 'error',
                            text: response.data.Data.Message,
                        })
                    }else{
                        await this.$swal({
                            timer: 1500,
                            showConfirmButton: false,
                            position: 'center',
                            type: 'success',
                            text: response.data.Data.Message,
                        })
                    }
                    this.loaddatacategory()
                }
                this.loading = false
            }
        },
        clearData(){
            this.CategoryID = null
            this.name = null
            this.description = null
            this.selectcategories = this.scategory
          },

    }
}
</script>
